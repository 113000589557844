import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress"; // LinearProgress for progress bar
import axios from "axios";
import Cookies from "js-cookie";
import { GlobalUrl, DrawerHeader } from "../Global";
import { useNavigate, useParams } from "react-router-dom";

function YourChildComponent({ file, fileTypePath, setShowModel }) {
  const [isPrivate, setIsPrivate] = React.useState(true);
  const [promptValue, setPromptValue] = React.useState(
    "You are a helpful assistant."
  );
  const [documentName, setDocumentName] = React.useState("");
  const [csvHeaders, setCsvHeaders] = React.useState([]);
  const [selectedInputColumns, setSelectedInputColumns] = React.useState([]);
  const [selectedOutputColumns, setSelectedOutputColumns] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false); // State to track loading
  const [taskId, setTaskId] = React.useState(null); // State to save the task ID
  const [taskStatus, setTaskStatus] = React.useState(null); // State to store task status
  const [taskLoading, setTaskLoading] = React.useState(false); // State for task status loading
  const navigate = useNavigate();

  const token = Cookies.get("sFrontToken");

  React.useEffect(() => {
    if (file && file.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        const rows = text.split("\n");
        let headers = rows[0].split(",");
        setCsvHeaders(headers);
      };
      reader.readAsText(file);
    } else {
      setCsvHeaders([]);
    }
  }, [file]);

  const handleCheckboxChange = (event, type) => {
    const value = event.target.name;
    const checked = event.target.checked;

    if (type === "input") {
      setSelectedInputColumns((prev) =>
        checked ? [...prev, value] : prev.filter((col) => col !== value)
      );
    } 
    // else if (type === "output") {
    //   setSelectedOutputColumns((prev) =>
    //     checked ? [...prev, value] : prev.filter((col) => col !== value)
    //   );
    // }
  };

  const handleSubmit = async () => {
    const body = {
      dataset_name: documentName,
      file_path: fileTypePath,
      system_prompt: promptValue,
      private: isPrivate,
    };

    setLoading(true);
    setErrorMessage("");

    try {
      let response;

      // Check if file is an array (for PDFs)
      if (Array.isArray(file) && file[0].type.includes("pdf")) {
        response = await axios.post(
          `${GlobalUrl}/doc/doc-query-store-vector`,
          {
            ...body,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      // Check if file is an object (for CSV)
      else if (file && file.type === "text/csv") {
        response = await axios.post(
          `${GlobalUrl}/doc/csv-qa-store-vector`,
          {
            ...body,
            csv_input_columns: selectedInputColumns,
            // csv_output_columns: selectedOutputColumns,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response && response.status === 200) {
        setTaskId(response.data.task_id);
        if(response.data.task_id){
          navigate(`/dataset/upload/${response.data.task_id}`);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchTaskStatus = async () => {
    if (taskId) {
      setTaskLoading(true); // Start loading for task status
      try {
        const response = await axios.get(`${GlobalUrl}/doc/get-task-status/${taskId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaskStatus(response.data); // Save task status to state
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } finally {
        setTaskLoading(false); // Set loading to false when the API call finishes
      }
    }
  };

  // Fetch task status if taskId is available
  React.useEffect(() => {
    fetchTaskStatus();
  }, [taskId, token]); // Fetch status when taskId changes

  // Conditionally render the task status or form based on taskId and taskStatus
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "#EEEEEE",
        minHeight: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DrawerHeader />

      <Box
        sx={{
          width: "100%",
          maxWidth: 900,
          padding: 3,
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        {!taskId && (
          <Typography variant="h5" gutterBottom align="center">
            Vector Convert Form
          </Typography>
        )}
        <form noValidate autoComplete="off">
            {file && file.type === "text/csv" && (
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      padding: 2,
                      backgroundColor: "#f5f5f5",
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Select Input Options
                    </Typography>
                    {csvHeaders.map((header, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={header}
                            color="primary"
                            onChange={(e) => handleCheckboxChange(e, "input")}
                          />
                        }
                        label={header}
                      />
                    ))}
                  </Paper>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      padding: 2,
                      backgroundColor: "#f5f5f5",
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Select Output Options
                    </Typography>
                    {csvHeaders.map((header, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={header}
                            color="primary"
                            onChange={(e) => handleCheckboxChange(e, "output")}
                          />
                        }
                        label={header}
                      />
                    ))}
                  </Paper>
                </Grid> */}
              </Grid>
            )}

            <TextField
              label="Dataset Name"
              placeholder="Enter dataset name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
            <TextField
              label="Prompt"
              value={promptValue}
              onChange={(e) => setPromptValue(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                  name="private"
                  color="primary"
                />
              }
              label="Private"
            />

            {/* Display Error Message */}
            {errorMessage && (
              <Typography color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}

            {/* Display Loading Indicator or Button */}
            {!loading ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                Convert
              </Button>
            ) : (
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <LinearProgress sx={{ width: "100%" }} />{" "}
                {/* Linear progress bar */}
              </Box>
            )}
          </form>
      </Box>
    </Box>
  );
}

export default YourChildComponent;
