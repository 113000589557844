import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PushPinIcon from "@mui/icons-material/PushPin";
import Refresh from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import axios from "axios";
import AppBars from "../AppBar/AppBar";
import Listitems from "../ListItems/ListItems";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { GlobalUrl, DrawerHeader, Drawer } from "../Global";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ReactMarkdown from "react-markdown";
import LogoImage from "./favicon-full-color-removebg-preview.png";

const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    minHeight: "0.1em !important", // Force smaller height with !important
    height: "12px !important",
    paddingBottom: "20px",
  },
});

export default function DetailsPageRoute() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [prompt, setPrompt] = React.useState("");
  const [modelName, setModelName] = React.useState("llama3_70b_chat");
  const [resultSource, setResultSource] = React.useState("1");
  const [searchLogic, setSearchLogic] = React.useState("1");
  const [searchTerm, setSearchTerm] = React.useState(""); // New state for search term
  const navigate = useNavigate();
  const Params = useParams();
  const DocParams = Params.type === "CSV_QA" ? "spark3-csv" : "spark3-doc";
  const decodedDatasetName = decodeURIComponent(Params.dataset_name);
  const [newloading, setNewLoading] = React.useState(false);
  const [boolensCheck, setBoolean] = React.useState(false);
  const [suggestedQuestions, setSuggestedQuestions] = React.useState([]); // State for suggestions
  const [searchHistory, setSearchHistory] = React.useState([]); // Store search terms and their responses
  const [drawerOpen, setDrawerOpen] = React.useState(true); // State for Drawer
  const [promptLoading, setPromptLoading] = React.useState(false);
  const [promptLoadingUpdate, setPromptLoadingUpdate] = React.useState(false);
  const [datasetId, setDataSetId] = React.useState();
  // State to track button color and pin status for each response
  const [likedResponses, setLikedResponses] = React.useState({});
  const [dislikedResponses, setDislikedResponses] = React.useState({});
  const [pinnedResponses, setPinnedResponses] = React.useState({});
  const [datas, setDatas] = React.useState("");
  const [responses, setResponses] = React.useState([]); // Stores questions and their responses
  const [currentConversationId, setCurrentConversationId] = React.useState("");
  const [pastConversationData, setPastConversationData] = React.useState([]); // Stores questions and their responses

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("sFrontToken");
        if (!token) {
          throw new Error("Access token is not available");
        }

        const response = await axios({
          method: "get",
          url: `${GlobalUrl}/doc/core-dataset-info?dataset_name=${decodedDatasetName}&index_name=${DocParams}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        setData(response.data.data);
        setPrompt(response.data.data.system_prompt);
        setDataSetId(response.data.data.dataset_id);
      } catch (err) {
        setError(err.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Params, DocParams]);

  const fetSuggestions = async (searchTerm) => {
    if (searchTerm) {
      try {
        const token = Cookies.get("sFrontToken");
        if (!token) {
          throw new Error("Access token is not available");
        }
        const response = await axios({
          method: "get",
          url: `${GlobalUrl}/doc/get-suggested-questions?index_name=${DocParams}&dataset_name=${decodedDatasetName}&search_query=${searchTerm}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        setSuggestedQuestions(response.data.suggested_questions);
      } catch (error) {}
    } else {
      try {
        const token = Cookies.get("sFrontToken");
        if (!token) {
          throw new Error("Access token is not available");
        }
        const response = await axios({
          method: "get",
          url: `${GlobalUrl}/doc/get-suggested-questions?index_name=${DocParams}&dataset_name=${decodedDatasetName}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        setSuggestedQuestions(response.data.suggested_questions);
      } catch (error) {}
    }
  };

  const fetchPastConversation = async () => {
    try {
      const token = Cookies.get("sFrontToken");
      if (!token) {
        throw new Error("Access token is not available");
      }
      const response = await axios({
        method: "get",
        url: `${GlobalUrl}/doc/get_conversations?dataset_id=${datasetId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setPastConversationData(response.data.past_conversation);
    } catch (error) {}
  };

  const loadConversation = async (conversationId) => {
    try {
      const token = Cookies.get("sFrontToken");
      if (!token) {
        throw new Error("Access token is not available");
      }
      const response = await axios({
        method: "get",
        url: `${GlobalUrl}/doc/get_conversation_histories?conversation_id=${conversationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const transformedConversationHistoryData = response.data.data.conversation_histories.map(item => ({
        question_id: item.id,
        currentSearchTerm: item.question,
        response: item.answer,
        conversation_id: item.conversation,
        error: false
      }));
    setResponses(transformedConversationHistoryData);
    setCurrentConversationId(conversationId)
    } catch (error) {}
  };

  

  React.useEffect(() => {
    fetSuggestions();
  }, []);
  React.useEffect(() => {
    fetchPastConversation();
  }, [datasetId]);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === "Private Collections") {
      navigate("/dataset/list/private");
    }
    if (text === "Collections") {
      navigate("/dataset/list/public");
    }
    if (text === "Upload New Dataset") {
      navigate("/upload");
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  const handleUpdatePrompt = async () => {
    const token = Cookies.get("sFrontToken");
    if (!token) {
      alert("Access token is not available");
      return;
    }

    const body = {
      dataset_name: `${decodedDatasetName}`,
      index_name: DocParams,
      system_prompt: prompt,
    };

    try {
      setPromptLoadingUpdate(true);
      setPromptLoading(true);
      const response = await axios.post(
        `${GlobalUrl}/doc/update-prompt`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setPromptLoading(false);
    } catch (err) {
      console.error("Error updating prompt:", err);
      setPromptLoading(false);
    }
  };

  React.useEffect(() => {
    if (promptLoadingUpdate) {
      setTimeout(() => {
        setPromptLoadingUpdate(false);
      }, "8000");
    }
  }, [promptLoadingUpdate]);

  const userQuesryApiCount = async (currentSearchTerm) => {
    const token = Cookies.get("sFrontToken");

    // Remove the previous response with the same question_id if it exists and has an error
    setResponses((prev) =>
      prev.filter((item) => item.currentSearchTerm !== currentSearchTerm)
    );

    // Initialize the response for the current search term
    setResponses((prev) => [
      ...prev,
      { currentSearchTerm, question_id: null,conversation_id: null, response: "", error: false },
    ]);

    const apiUrl = `${GlobalUrl}/doc/user-query-stream?model_name=${modelName}&index_name=${DocParams}&dataset_name=${decodedDatasetName}&local_search=${resultSource}&logic=${searchLogic}&system_prompt=${prompt}&search_query=${currentSearchTerm}&conversation_id=${currentConversationId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check for non-2xx HTTP status codes
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk
          .split("\n")
          .filter((line) => line.trim() !== "")
          .map((line) => {
            try {
              // Parse each line into JSON
              const parsed = JSON.parse(line.replace("data: ", "").trim());
              return {
                text: parsed.text || "",
                question_id: parsed.question_id || null,
                conversation_id: parsed.conversation_id || null,
              };
            } catch (error) {
              console.error("Error parsing line:", line, error);
              return null;
            }
          })
          .filter((parsed) => parsed !== null); // Remove null values from invalid JSON

        // Update the response for the specific currentSearchTerm
        setResponses((prev) =>
          prev.map((item) =>
            item.currentSearchTerm === currentSearchTerm
              ? {
                  ...item,
                  question_id: lines[0]?.question_id || item.question_id,
                  conversation_id: lines[0]?.conversation_id || item.conversation_id,
                  response:
                    item.response + lines.map((line) => line.text).join(" "),
                  error: false, // Clear error if data comes through
                }
              : item
          )
        );

        const conversation_id = lines[0]?.conversation_id
        setCurrentConversationId(conversation_id)
      }
    } catch (error) {
      setResponses((prev) =>
        prev.map((item) =>
          item.currentSearchTerm === currentSearchTerm
            ? { ...item, error: true }
            : item
        )
      );
      console.error("Error fetching data:", error);
    }
  };

  const apiCallWithCount = async (currentSearchTerm) => {
    const token = Cookies.get("sFrontToken");
    if (currentSearchTerm) {
      try {
        const response = await axios.get(
          `${GlobalUrl}/doc/user-query-stream?model_name=${modelName}&index_name=${DocParams}&dataset_name=${decodedDatasetName}&local_search=${resultSource}&logic=${searchLogic}&count=5&system_prompt=${prompt}&search_query=${currentSearchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Split the raw response data into individual lines
        const dataArray = response.data
          .split("\n")
          .filter((item) => item.trim() !== "");

        // Parse each data entry and extract the `text`
        const parsedData = dataArray.map((item) => {
          const jsonString = item.replace("data: ", "").trim();
          return JSON.parse(jsonString);
        });

        // Concatenate all `text` values without modification
        const concatenatedText = parsedData.map((item) => item.text).join("");
        const questionIds =
          parsedData.length > 0 ? parsedData[0].question_id : null;
        return {
          data: concatenatedText,
          question_id: questionIds,
          error: false,
        }; // Return text and question_id
      } catch (error) {
        console.error("Error fetching data:", error);
        return { data: null, error: true }; // Error flag
      }
    } else {
      try {
        const response = await axios.get(
          `${GlobalUrl}/doc/user-query-stream?model_name=${modelName}&index_name=${DocParams}&dataset_name=${decodedDatasetName}&local_search=${resultSource}&logic=${searchLogic}&count=5&system_prompt=${prompt}&search_query=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Split the raw response data into individual lines
        const dataArray = response.data
          .split("\n")
          .filter((item) => item.trim() !== "");

        // Parse each data entry and extract the `text`
        const parsedData = dataArray.map((item) => {
          const jsonString = item.replace("data: ", "").trim();
          return JSON.parse(jsonString);
        });

        // Concatenate all `text` values without modification
        const concatenatedText = parsedData.map((item) => item.text).join("");
        const questionIds =
          parsedData.length > 0 ? parsedData[0].question_id : null;
        return {
          data: concatenatedText,
          question_id: questionIds,
          error: false,
        }; // Return text and question_id
      } catch (error) {
        console.error("Error fetching data:", error);
        return { data: null, error: true }; // Error flag
      }
    }
  };

  let apiCallCount = 0; // Declare outside the function to persist across calls
  const fetchApiData = async (currentSearchTerm) => {
    setNewLoading(true);
    let allData = [];
    let allSuggestedQuestions = [];

    for (let i = 1; i <= 1; i++) {
      const result = await apiCallWithCount(currentSearchTerm);

      allData.push(result);

      setSearchHistory((prev) => {
        const lastEntry = prev[prev.length - 1];
        if (lastEntry && lastEntry.term === currentSearchTerm) {
          return [
            ...prev.slice(0, -1),
            {
              ...lastEntry,
              responses: [
                ...lastEntry.responses,
                { data: result.data, error: result.error },
              ],
            },
          ];
        } else {
          return [
            ...prev,
            {
              term: currentSearchTerm,
              responses: [{ data: result.data, error: result.error }],
            },
          ];
        }
      });
    }

    setNewLoading(false);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchHistory((prev) => [...prev, { term: searchTerm, responses: [] }]); // Initialize new search entry
    userQuesryApiCount(searchTerm);
    fetSuggestions(searchTerm);
    setSearchTerm(""); // Clear the input field
  };

  // Handlers for button actions
  const handleLikeClick = (questionId) => {
    setLikedResponses((prev) => ({
      ...prev,
      [questionId]: !prev[questionId], // Toggle liked status
    }));
    updateQuestionAction(questionId, "like");
  };

  const handleDislikeClick = (questionId) => {
    setDislikedResponses((prev) => ({
      ...prev,
      [questionId]: !prev[questionId], // Toggle disliked status
    }));
    updateQuestionAction(questionId, "dis_like");
  };

  const handlePinClick = (questionId, answer) => {
    setPinnedResponses((prev) => ({
      ...prev,
      [questionId]: !prev[questionId], // Toggle pinned status
    }));
    updateQuestionAction(questionId, "pin", answer);
  };

  // API call function
  const updateQuestionAction = async (questionId, action, answer = null) => {
    try {
      const token = Cookies.get("sFrontToken");
      await axios.post(
        `${GlobalUrl}/doc/update-question/${questionId}`,
        { action, answer },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setSearchTerm(suggestion); // Set the search term for the API call
    setBoolean(!boolensCheck);
    // Fetch API data based on the clicked suggestion immediately
    // await fetchApiData(suggestion);
  };

  React.useEffect(() => {
    if (searchTerm) {
      // fetchApiData(searchTerm);
      fetSuggestions(searchTerm);
      userQuesryApiCount(searchTerm);
      setSearchTerm("");
    }
  }, [boolensCheck]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#F3F3F3",
          minHeight: "100vh",
          // justifyContent: 'center',
        }}
      >
        <DrawerHeader />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {error && <Typography color="error">Error: {error}</Typography>}
            {data && (
              <Card
                variant="outlined"
                sx={{
                  margin: { xs: 1, sm: 2 }, // Margin for small screens
                  padding: { xs: 1, sm: 2 }, // Padding for small screens
                  maxHeight: { xs: "90vh", sm: "auto" }, // Limit height for small screens
                  overflowY: "auto", // Enable scrolling on overflow
                }}
              >
                <CardContent>
                  <IconButton onClick={toggleDrawer}>
                    <MenuIcon />
                  </IconButton>
                  {drawerOpen == true && (
                    <Card
                      variant="outlined"
                      sx={{
                        padding: { xs: 1, sm: 2 },
                        margin: { xs: 1, sm: 2 },
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              fontSize: { xs: "1.2rem", sm: "1.5rem" }, // Responsive font size
                            }}
                          >
                            Dataset Name: {decodedDatasetName || "N/A"}
                          </Typography>
                          <Typography
                            sx={{ fontSize: { xs: 12, sm: 14 } }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Record Count: {data.record_count || "N/A"}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: { xs: "0.9rem", sm: "1rem" },
                            }}
                          >
                            Type: {Params.type || "N/A"}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                          >
                            Prompt
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={prompt}
                              onChange={(e) => setPrompt(e.target.value)}
                              placeholder="Enter your prompt"
                              multiline
                              rows={2}
                              maxRows={2}
                              sx={{
                                flexGrow: 1,
                                marginRight: 1,
                                width: { xs: "100%", sm: "auto" }, // Full width on small screens
                              }}
                            />
                            {promptLoading ? (
                              <Box sx={{ marginLeft: 1 }}>
                                <CircularProgress />
                              </Box>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={handleUpdatePrompt}
                                disabled={promptLoadingUpdate}
                                sx={{
                                  color: promptLoadingUpdate
                                    ? "rgba(18, 17, 17, 0.94)"
                                    : "white",
                                  fontSize: { xs: "0.8rem", sm: "1rem" },
                                }}
                              >
                                {promptLoadingUpdate ? "Updated" : "Update"}
                              </Button>
                            )}
                          </Box>
                        </Grid>

                        <Grid
                          container
                          spacing={{ xs: 1, sm: 2 }}
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Stack items vertically on small screens
                            flexWrap: "nowrap",
                          }}
                        >
                          <Grid item sx={{ flexGrow: 1, width: "100%" }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Model Name</InputLabel>
                              <CustomSelect
                                value={modelName}
                                onChange={(e) => setModelName(e.target.value)}
                                label="Model Name"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="llama3_70b_chat">
                                  llama3_70b_chat
                                </MenuItem>
                              </CustomSelect>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ flexGrow: 1, width: "100%" }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Result From</InputLabel>
                              <CustomSelect
                                value={resultSource}
                                onChange={(e) =>
                                  setResultSource(e.target.value)
                                }
                                label="Result From"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="1">Local</MenuItem>
                                <MenuItem value="2">Local & Global</MenuItem>
                              </CustomSelect>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ flexGrow: 1, width: "100%" }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Search Logic</InputLabel>
                              <CustomSelect
                                value={searchLogic}
                                onChange={(e) => setSearchLogic(e.target.value)}
                                label="Search Logic"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="1">Logic1</MenuItem>
                                <MenuItem value="2">Logic2</MenuItem>
                              </CustomSelect>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {responses.map((item, index) => (
                        <Grid
                          item
                          xs={12}
                          key={index}
                          sx={{ marginTop: "10px" }}
                        >
                          <Card
                            variant="outlined"
                            sx={{
                              margin: { xs: "8px auto", sm: "10px auto" },
                              padding: 2,
                              borderRadius: "15px",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                              width: "100%",
                              maxWidth: { xs: "95vw", sm: "80vw" },
                              overflowWrap: "break-word",
                            }}
                          >
                            <CardContent>
                              <Typography variant="h6">
                                {item.currentSearchTerm}
                              </Typography>
                              <Typography
                                component="div"
                                sx={{ wordWrap: "break-word", marginTop: 1 }}
                                variant="body1"
                              >
                                {item.response ? (
                                  <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    gap={1}
                                  >
                                    {/* Image */}
                                    <img
                                      src={LogoImage}
                                      alt="response log"
                                      style={{ maxWidth: 30, marginTop: 11 }}
                                    />

                                    {/* Response Text */}
                                    <Box>
                                      <ReactMarkdown>
                                        {item.response}
                                      </ReactMarkdown>
                                    </Box>
                                  </Box>
                                ) : item.error ? (
                                  <>
                                    <Typography sx={{fontSize: "18px", marginBottom: '20px'}} color="error">
                                      There was an error generating a response
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#000", // Black background
                                        color: "#fff", // White text
                                        borderRadius: "20px", // Rounded corners
                                        padding: "8px 16px", // Padding for size
                                        textTransform: "none", // Disable uppercase text
                                        fontSize: "14px", // Adjust font size
                                        boxShadow: "none", // Remove shadow
                                        "&:hover": {
                                          backgroundColor: "#333", // Slightly lighter on hover
                                        },
                                      }}
                                      onClick={() => {
                                        userQuesryApiCount(
                                          item.currentSearchTerm
                                        );
                                        fetSuggestions(item.currentSearchTerm);
                                      }}
                                    >
                                      <Refresh sx={{ marginRight: "8px" }} />{" "}
                                      {/* Add a refresh icon */}
                                      Regenerate
                                    </Button>
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      "@keyframes flashing": {
                                        "0%": { opacity: 1 },
                                        "50%": { opacity: 0.5 },
                                        "100%": { opacity: 1 },
                                      },
                                      animation: "flashing 1s infinite",
                                      color: "#4e545c", // Flashing color
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Generating...
                                  </Box>
                                )}
                              </Typography>
                            </CardContent>
                            {!item.error && (
                              <CardActions
                                sx={{
                                  justifyContent: "flex-end",
                                  padding: "8px",
                                }}
                              >
                                <Box sx={{ display: "flex", gap: "8px" }}>
                                  <IconButton
                                    size="small"
                                    color={
                                      likedResponses[item?.question_id]
                                        ? "primary"
                                        : "default"
                                    }
                                    onClick={() =>
                                      handleLikeClick(item?.question_id)
                                    }
                                  >
                                    <ThumbUpIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    color={
                                      dislikedResponses[item?.question_id]
                                        ? "primary"
                                        : "default"
                                    }
                                    onClick={() =>
                                      handleDislikeClick(item?.question_id)
                                    }
                                  >
                                    <ThumbDownIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    color={
                                      pinnedResponses[item?.question_id]
                                        ? "primary"
                                        : "default"
                                    }
                                    onClick={() =>
                                      handlePinClick(
                                        item?.question_id,
                                        item?.response
                                      )
                                    }
                                  >
                                    <PushPinIcon />
                                  </IconButton>
                                </Box>
                              </CardActions>
                            )}
                          </Card>
                        </Grid>
                      ))}

                      {newloading ? (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12} sx={{ padding: { xs: 1, sm: 2 } }}>
                            <Typography variant="h6">Suggestions</Typography>
                            <ul>
                              {suggestedQuestions?.length > 0 ? (
                                suggestedQuestions?.map((suggestion, index) => (
                                  <li key={index}>
                                    <Typography
                                      onClick={() =>
                                        handleSuggestionClick(suggestion)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {suggestion}
                                    </Typography>
                                  </li>
                                ))
                              ) : (
                                <Typography>
                                  <CircularProgress />
                                </Typography>
                              )}
                            </ul>
                          </Grid>
                          <Grid item xs={12} sx={{ padding: { xs: 1, sm: 2 } }}>
                            <Typography variant="h6">Search</Typography>
                            <Box
                              component="form"
                              onSubmit={handleSearch}
                              sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                gap: 1,
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  border: "1px solid #ccc",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  marginBottom: { xs: "10px", sm: "0" },
                                }}
                              />
                              <Button variant="contained" type="submit">
                                Search
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  {/* test past conversation cards */}
                  <Box
                    sx={{
                      paddingRight: "16px",
                      marginLeft: "30px",
                    }}
                  >
                    <Grid container spacing={2}>
                      {pastConversationData.map((item) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                          <Card
                            sx={{
                              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                              textAlign: "center",
                              padding: 2,
                              backgroundColor:
                                "#F9FAFB" ,
                              width: "100%", // Full width for responsiveness
                              height: { xs: "80px", md: "80px" }, // Square-like shape
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center", // Center content vertically
                              alignItems: "center",
                              borderRadius: 2,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              loadConversation(item.id)
                            }
                          >
                            <CardContent
                              sx={{
                                width: "100%",
                                maxHeight: "100%", // Keep content within bounds
                                overflow: "hidden", // Hide any overflow
                                textAlign: "center",
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontSize: { xs: "10px", md: "12px" },
                                  color: "#4a4a4a",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                  marginBottom: 1,
                                  whiteSpace: "normal",
                                  wordWrap: "break-word", // Allows long text to wrap
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                }}
                              >
                                {item.conversation_name}
                              </Typography>
                            </CardContent>
                            <CardActions
                              sx={{
                                display: "flex",
                                justifyContent: "center", // Ensure the button and icons/data are spaced apart
                                alignItems: "center", // Align vertically
                                width: "100%", // Full width of the card
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1, // Space between icons or elements
                                }}
                              >
                                {new Date(item.created_at).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "2-digit",
                                })}
                              </Box>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  {/* end test past conversation */}

                </CardContent>
              </Card>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
